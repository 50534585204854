.locationsRoot {
    min-height: calc(100vh - 60px);
    background-color: #11284b !important;
    background-size: cover !important;
    background-position: center !important;
    background-image: linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%),
    url(https://images.unsplash.com/photo-1622007151631-25aa98ab394b?q=80&w=3291&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    padding-top: calc(var(--mantine-spacing-xl) * 3) !important;
    /* padding-bottom: calc(var(--mantine-spacing-xl) * 3) !important; */
}

.locationsInner {
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important; /* Ensures better layout behavior on smaller screens */

    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        flex-direction: column !important;
    }
}

.locationsImage {
    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        display: none !important;
    }
}

.locationsContent {
    padding-top: calc(var(--mantine-spacing-xl) * 2) !important;
    padding-bottom: calc(var(--mantine-spacing-xl) * 2) !important;
    margin-right: calc(var(--mantine-spacing-xl) * 3) !important;

    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        margin-right: 0 !important;
    }
}

.locationsTitle {
    color: var(--mantine-color-white) !important;
    font-family: 'Greycliff CF', var(--mantine-font-family) !important;
    font-weight: 900 !important;
    line-height: 1.05 !important;
    max-width: 500px !important;
    font-size: 48px !important;

    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        max-width: 100% !important;
        font-size: 34px !important;
        line-height: 1.15 !important;
    }
}

.locationsHouston {
    color: var(--blackbox-blue) !important;
}

.locationsDescription {
    color: var(--mantine-color-white) !important;
    opacity: 0.75 !important;
    max-width: 500px !important;

    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        max-width: 100% !important;
    }
}

.locationsControl {
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-family: 'Greycliff CF', var(--mantine-font-family) !important;
    font-size: 22px !important;
    background-color: var(--blackbox-blue);

    @media (max-width: 768px) { /* Replaced custom variable with standard pixel-based breakpoint */
        width: 100% !important;
    }
}