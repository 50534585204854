.pricingCard {
    background-color: var(--mantine-color-body);
  }
  
  .pricingImageSection {
    padding: var(--mantine-spacing-md);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .pricingLabel {
    margin-bottom: var(--mantine-spacing-xs);
    line-height: 1;
    font-weight: 700;
    font-size: var(--mantine-font-size-xs);
    letter-spacing: -0.25px;
    text-transform: uppercase;
  }
  
  .pricingSection {
    padding: var(--mantine-spacing-md);
    border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .pricingIcon {
    margin-right: 8px;
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-2));
  }

  .pricingHeroWrapper {
    position: relative;
    box-sizing: border-box;
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
  }
  
  .pricingHeroInner {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
  
    @media (max-width: var(--mantine-breakpoint-sm ) ) {
      padding-bottom: 80px;
      padding-top: 80px;
    }
  }
  
  .pricingHeroTitle {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: 62px;
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  
    @media (max-width: var(--mantine-breakpoint-sm) ) {
      font-size: 42px;
      line-height: 1.2;
    }
  }
  
  .pricingHeroDescription {
    margin-top: var(--mantine-spacing-xl) !important;
    font-size: 24px !important;
  
    @media (max-width: var(--mantine-breakpoint-sm) ) {
      font-size: 18px !important;
    }
  }
  
  .pricingHeroControls {
    margin-top: calc(var(--mantine-spacing-xl) * 2);
  
    @media (max-width: var(--mantine-breakpoint-sm )) {
      margin-top: var(--mantine-spacing-xl);
    }
  }
  
  .pricingHeroControl {
    height: 54px;
    padding-left: 38px;
    padding-right: 38px;
  
    @media (max-width: var(--mantine-breakpoint-sm ) ) {
      height: 54px;
      padding-left: 18px;
      padding-right: 18px;
      flex: 1;
    }
  }

  .pricingFeatureItem {
    display: flex;
    align-items: center;
  }
