@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

:root {
  --mantine-breakpoint-xs: '36em';
  --mantine-breakpoint-sm: '48em';
  --mantine-breakpoint-md: '62em';
  --mantine-breakpoint-lg: '75em';
  --mantine-breakpoint-xl: '88em';
  --mantine-spacing-md: calc(1rem * var(--mantine-scale));
  --blackbox-blue: #63B0C1;
  --blackbox-blue-2: #5396A6;
  --mantine-color-blue-filled: var(--blackbox-blue) !important;
  --mantine-color-blue-filled-hover: var(--blackbox-blue-2) !important;
}

.mantine-AppShell-root .mantine-AppShell-main {
  padding-inline-end: 0;
}

.Footer_footer__o4-YU {
  padding-bottom: 0 !important;
}

.Footer_footerLogo__CrjkN {
  flex-grow: 1;
}

main {
  padding-left: 0 !important;
  padding-top: 60px !important;
  z-index: 1000000;
}


header {
  & > div {
    --mantine-scale: 0;
    & * {
      --mantine-scale: 1;
    }
  }
}

.custom-header {
  background-color: rgba(255, 255, 255, 0); /* Transparent white */
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  z-index: 10; /* Keeps it above content */
}

input,
textarea,
select {
  font-size: 16px !important; /* Prevent zooming */
  appearance: none !important; /* Improve consistency */
}

.mantine-Accordion-root .mantine-Accordion-item .mantine-Accordion-control[aria-expanded=true] {
  background-color: var(--blackbox-blue);
  color: white;
}
