.notFoundRoot {
    height: calc(100vh - 60px); /* Accounts for header height */
    display: flex; /* Enables Flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center; /* Ensure text alignment */
    padding: 0 20px; /* Optional horizontal padding for spacing */
}

.notFoundLabel {
    font-weight: 900 !important;
    font-size: 38px !important;
    line-height: 1 !important;
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl)) !important;
    color: var(--mantine-color-gray-2) !important;
  
    @media (max-width: var(--mantine-breakpoint-sm) ) {
        font-size: 32px !important;
    }
}

.notFoundDescription {
    max-width: 500px !important;
    margin-top: var(--mantine-spacing-xl) !important;
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl)) !important;
}

.notFoundTitle {
    font-family: 'Greycliff CF', var(--mantine-font-family) !important;
    font-weight: 900 !important;
    font-size: 38px !important;
    margin-bottom: var(--mantine-spacing-xl) !important;

    @media (max-width: var(--mantine-breakpoint-sm) ) {
        font-size: 32px !important;
    }
}