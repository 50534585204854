.footer {
    /* margin-top: 120px !important; */
    padding-top: calc(var(--mantine-spacing-xl) * 2) !important;
    padding-bottom: calc(var(--mantine-spacing-xl) * 2) !important;
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6)) !important;
    border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5)) !important;
  }
  
  .footerLogo {
    max-width: 200px !important;
  
    @media (max-width: 48em) {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
    }
  }
  
  .footerDescription {
    margin-top: 5px !important;
  
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs) !important;
      text-align: center !important;
    }
  }
  
  .footerInner {
    display: flex !important;
    justify-content: space-between !important;
  
    @media (max-width: 48em) {
      flex-direction: column !important;
      align-items: center !important;
    }
  }
  
  .footerGroups {
    display: flex !important;
    flex-wrap: wrap !important;
  
    @media (max-width: 48em) {
      display: none !important;
    }
  }
  
  .footerWrapper {
    width: 160px !important;
  }
  
  .footerLink {
    display: block !important;
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1)) !important;
    font-size: var(--mantine-font-size-sm) !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .footerTitle {
    font-size: var(--mantine-font-size-lg) !important;
    font-weight: 700 !important;
    font-family:
      Greycliff CF,
      var(--mantine-font-family) !important;
    margin-bottom: calc(var(--mantine-spacing-xs) / 2) !important;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white)) !important;
  }
  
  .afterFooter {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: var(--mantine-spacing-xl) !important;
    padding-top: var(--mantine-spacing-xl) !important;
    padding-bottom: var(--mantine-spacing-xl) !important;
    border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4)) !important;
  
    @media (max-width: 48em) {
      flex-direction: column !important;
    }
  }
  
  .footerSocial {
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs) !important;
    }
  }