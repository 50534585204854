.hero {
    position: relative;
    height: calc(100vh - 60px); /* Account for the header height */
    width: 100%; /* Full width */
    background-image: url('../../assets/images/background/Home_Hero.png');
    background-size: cover;
    background-position: center;
    display: flex; /* Enables Flexbox for alignment */
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    text-align: center; /* Center-align text within the container */
}

.heroContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within the container */
    z-index: 1; /* Keeps the content above the overlay */
}

.heroTitle {
    color: var(--mantine-color-white) !important;
    font-size: 60px !important;
    font-weight: 900 !important;
    line-height: 1.1 !important;

    @media (max-width: 48em) {
        font-size: 40px !important;
        line-height: 1.2 !important;
    }

    @media (max-width: 36em) {
        font-size: 40px !important;
        line-height: 1.3 !important;
    }
}

.heroDescription {
    color: var(--mantine-color-white) !important;
    margin-top: var(--mantine-spacing-xl) !important;
    font-size: 24px !important;
  
    @media (max-width: 36em) {
      font-size: 18px !important;
    }
}

.heroControl {
    background-color: var(--blackbox-blue) !important;
    margin-top: calc(var(--mantine-spacing-xl) * 1.5) !important;

    @media (max-width: 48em) {
        width: 100% !important;
    }
}