.wrapper {
    padding: calc(var(--mantine-spacing-xl) * 2) var(--mantine-spacing-xl);
}

.title {
    font-family: 'Greycliff CF', var(--mantine-font-family);
    font-size: 36px;
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: var(--mantine-spacing-md);
    color: var(--mantine-color-black);
}

.title[data-mantine-color-scheme='dark'] {
    color: var(--mantine-color-white);
}

.tabsList {
    display: flex;
    border-bottom: 1px solid var(--mantine-color-gray-3) !important;
}

.tabsList[data-mantine-color-scheme='dark'] {
    border-bottom: 1px solid var(--mantine-color-dark-4) !important;
}

.tab {
    position: relative;
    border: 1px solid var(--mantine-color-gray-3) !important;
    background-color: var(--mantine-color-white);
    border-radius: 0; /* Remove rounding by default */
    padding: calc(var(--mantine-spacing-xs)) calc(var(--mantine-spacing-md));
    transition: background-color 150ms ease, color 150ms ease;
    z-index: 0; /* Ensure tabs don't overlap unnecessarily */
}

.tab:first-of-type {
    border-radius: 8px 0 0 8px; /* Round only the first tab */
}

.tab:last-of-type {
    border-radius: 0 8px 8px 0; /* Round only the last tab */
}

.tab + .tab {
    border-left: none !important; /* Remove left border for adjacent tabs */
  }

.tab[data-mantine-color-scheme='dark'] {
    border: 2px solid var(--mantine-color-dark-4);
    background-color: var(--mantine-color-dark-6);
}

.tab:hover {
    background-color: var(--mantine-color-gray-0);
}

.tab[data-mantine-color-scheme='dark']:hover {
    background-color: var(--mantine-color-dark-5);
}

.tab[data-active] {
    z-index: 1;
    background-color: var(--blackbox-blue);
    border-color: var(--blackbox-blue);
    color: var(--mantine-color-white);
}

.tab[data-active]:hover {
    background-color: var(--blackbox-blue-2);
}

/* Ensure adjacent tabs don't overlap borders */
.tab + .tab {
    border-left: none; /* Prevent duplicate border between tabs */
}