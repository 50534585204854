.contactUsOuterWrap {
    padding: calc(0.75rem * var(--mantine-scale));
    position: relative;
    min-height: calc(100vh - 60px); /* Account for the header height */
}

.contactUsInnerWrap {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(62.5rem * var(--mantine-scale));
}

.contactUsWrapper {
    min-height: 400px !important;
    border-radius: var(--mantine-radius-md) !important;
    padding: 0 calc(var(--mantine-spacing-xl) * 2.5) !important;

    @media (max-width: 600px) {
        padding: calc(var(--mantine-spacing-xl) * 1.5) !important;
    }
}

.contactUsTable {
    @media (max-width: 48em) {
        --sg-cols: 1 !important;
    }
}


.contactUsTitle {
    font-family: Greycliff CF, var(--mantine-font-family) !important;
    color: var(--mantine-color-white) !important;
    line-height: 1 !important;
}

.contactUsDescription {
    color: white !important;
    max-width: 300px !important;

    @media (max-width: 600px) {
        max-width: 100% !important;
    }
}

.errorMessage {
    color: red !important;
    margin: 0;
}

.contactUsForm {
    background-color: var(--mantine-color-white) !important;
    padding: var(--mantine-spacing-xl) !important;
    border-radius: var(--mantine-radius-md) !important;
    box-shadow: var(--mantine-shadow-lg) !important;
}

.contactUsSocial {
    color: var(--mantine-color-white) !important;
}

.contactUsSocial:hover {
    color: var(--blackbox-blue) !important;
}

.contactUsInput {
    background-color: var(--mantine-color-white) !important;
    border-color: var(--mantine-color-gray-4) !important;
    color: var(--mantine-color-black) !important;
}

.contactUsInput::placeholder {
    color: var(--mantine-color-gray-5) !important;
}

.contactUsInputLabel {
    color: var(--mantine-color-black) !important;
}

.contactUsControl {
    background-color: var(--blackbox-blue) !important;
}
