.pricingCard {
  background-color: var(--mantine-color-body);
}

.pricingLabel {
  margin-bottom: var(--mantine-spacing-xs);
  line-height: 1;
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
  letter-spacing: -0.25px;
  text-transform: uppercase;
}

.pricingSection {
  padding: var(--mantine-spacing-md);
  border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.pricingIcon {
  margin-right: 8px;
  color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-2));
}

.pricingFeatureItem {
  display: flex;
  align-items: center;
}
