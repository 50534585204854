.customHeader {
    height: 60px;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);

    position: fixed; /* Keeps the header at the top */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Places the header above other elements */

    /* Background with transparency for the blur effect */
    background-color: rgba(255, 255, 255, 0.6); 
    backdrop-filter: blur(10px); /* Applies blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    
    /* Optional border */
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

/* Dark mode styling for the header */
.customHeader[data-mantine-color-scheme="dark"] {
    background-color: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid rgba(50, 50, 50, 0.5);
}

.customHeaderCenter {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 150px;
}

.customHeaderRight {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 230px; /* Ensures equal width for both sections */

    @media (max-width: 768px) {
        width: 50px;
    }
  }

  .customHeaderRightBurger {
    display: none;
    justify-content: end;
    align-items: center;
    width: 230px; /* Ensures equal width for both sections */

    @media (max-width: 768px) {
        display: flex;
        width: 10px;
    }
  }

.customHeaderLeft {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 230px !important; /* Ensures equal width for both sections */
}

.customHeaderImg {
    padding-right: 10px;
    user-select: none;
    pointer-events: none;
}

.customHeaderLink {
    display: flex;
    align-items: center;
    height: 100%;
    padding: var(--mantine-spacing-md);
    /* padding-right: var(--mantine-spacing-md); */
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-weight: 500;
    font-size: var(--mantine-font-size-md);

    @media (max-width: 48em ) {
        height: 42px;
        width: 100%;
    }
}

.customHeaderLink:hover {
    color: var(--blackbox-blue-2);
}

.loginInLink {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-weight: 600;
    font-size: var(--mantine-font-size-md);

    @media (max-width: 48em ) {
        height: 42px;
        width: 100%;
    }
}

.loginInLink:hover {
    color: var(--blackbox-blue-2);
}

.customHeaderButton {
    background-color: var(--blackbox-blue);
    font-size: var(--mantine-font-size-md);
}

.customHeaderButtonInverse {
    border-color: none;
    border-width: 1px;
    color: #030303;
    font-size: var(--mantine-font-size-md);
}

.customHeaderSubLink {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
}

.customHeaderDropdownFooter {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}