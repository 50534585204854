.contactIconWrapper {
    display: flex !important;
    align-items: center !important;
    color: var(--mantine-color-white) !important;
  }
  
  .contactIcon {
    margin-right: var(--mantine-spacing-md) !important;
    background-color: transparent !important;
  }
  
  .contactIconTitle {
    color: var(--mantine-color-blue-0) !important;
  }
  
  .contactIconDescription {
    color: var(--mantine-color-white) !important;
  }